const AppName = process.env.VUE_APP_NAME;
const AppUrl = process.env.VUE_APP_CORE;

const BHAPI = process.env.VUE_APP_BH_API;
const BHSCOPE = process.env.VUE_APP_BH_API_SCOPE;
const RangerApp = process.env.VUE_APP_RANGER_APP;

const AppClientID = process.env.VUE_APP_CLIENT_ID;
const AppLoginAuthority = process.env.VUE_APP_AUTHORITY;
const AppLoginFlow = process.env.VUE_APP_LOGIN_FLOW;
const AppSignUpFlow = process.env.VUE_APP_SIGNUP_FLOW;
const currentApp = "bh";
const AppScope = ["openid", "profile", `${AppClientID}`]

export const BaseConfig = {
  AppName,
  AppUrl,
  RangerApp,
  BHAPI,
  BHSCOPE,
  AppClientID,
  AppLoginAuthority,
  AppLoginFlow,
  AppSignUpFlow,
  currentApp,
  AppScope
};
